// CORPORATIVES COLORS
$main-color: #002841;
$secondary-color: #335367;
$tertiary-color: #35abc6;
$complementary-color: #0028411a;

// GENERAL COLORS
$black-color: #000000;
$black-color-20perc: #00000033;
$white-color: #ffffff;
$pepper-color: #df0024;
$white-color-30perc: #ffffff4d;
$red-highlight-color: #be020b1a;
$orange-highlight-color: #ed8b001a;
$very-light-green: #e6f3e6;
$very-light-red: #f8e6e7;
$light-secondary-color: #33536730;

// NOTIFICATIONS COLORS
$error-color: #be020b;
$error-highlight-color: #b804211a;
$success-color: #058303;
$success-highlight-color: #0583031a;
$warning-color: #ed8b00;
$warning-highlight-color: #ffd1001a;
$warning-dark-highlight-color: #ed8b0080;
$info-color: #35abc6;
$info-highlight-color: #35abc633;

// NEUTRAL COLORS
$neutral-dark-color: #9d9d9d;
$neutral-regular-color: #bdbdbd;
$neutral-light-color: #e0e0e0;
$neutral-extra-light-color: #fafafa;
$neutral-light-color-50perc: #e0e0e080;
$neutral-red-color: #f5d9da;
$neutral-tertiary-color: #35abc61a;

// I&E COLORS
$green-color: #058303;
$amber-color: #ed8b00;
$red-color: #be020b;
