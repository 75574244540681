.m{

    &-t-1{
        margin-top: 1em;
    }

    &-t-2{
                     margin-top: 2em;
    }

    &-t-3{
        margin-top: 3em;
    }

    &-l-1{
        margin-left: 1em;
    }

    &-l-2{
        margin-left: 2em;
    }

    &-l-3{
        margin-left: 3em;
    }

    &-r-1{
        margin-right: 1em;
    }

    &-r-2{
        margin-right: 2em;
    }

    &-r-3{
        margin-right: 3em;
    }

    &-b-1{
        margin-bottom: 1em;
    }

    &-b-2{
        margin-bottom: 2em;
    }

    &-b-3                                                                                                                                                                                                                                                                                                                 {
        margin-bottom: 3em;
    }

}