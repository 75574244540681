@import "./color_variables.scss";

//HEADERS
.h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.h2-medium {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 19px !important;
}

//LABELS
.copy-regular {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 15px !important;
}

.copy-medium {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 15px !important;
}

.copy-bold {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 15px !important;
}

.input-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

//LABEL COLORS
.url-link {
  text-decoration: underline;
  @extend .dark-blue;
}

.dark-blue {
  color: $main-color !important;
}

.orange {
  color: $warning-color !important;
}

.grey {
  color: $neutral-dark-color !important;
}

.dark-blue {
  color: $main-color !important;
}

.light-blue {
  color: $tertiary-color !important;
}

.white {
  color: $white-color !important;
}

.black {
  color: $black-color !important;
}

.red {
  color: $pepper-color !important;
}

.negative-result {
  font-weight: bold !important;
  color: $pepper-color !important;
}

.positive-result {
  font-weight: bold !important;
  color: $success-color !important;
}

.green {
  color: $success-color !important;
}

.error,
.error_small {
  color: $error-color !important;
}

//HIPERLINKS
a {
  color: $main-color;
}

a:hover {
  color: $success-color;
}

.label-grey {
  @extend .copy-bold;
  @extend .grey;
}
